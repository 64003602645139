@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  font-weight: 600 !important;
  color: #002768;
}
.font-size-fit
{
  font-size: 14px;
}

@media print {
  body {
      margin: 0; /* Remove default body margin */
      font-family: Arial, sans-serif; /* Set a clean font */
      background-color: white; /* White background for printing */
  }

  #contentToPrint {
      padding: 20px; /* Padding around content */
      border: none; /* Remove borders */
      background-color: white; /* Ensure background is white */
      box-shadow: none; /* Remove any box shadow */
  }

  h1 {
      color: black; /* Header color for print */
      font-size: 24px; /* Font size for headers */
      margin-bottom: 10px; /* Space below the header */
  }

  h2 {
      color: black; /* Header color for print */
      font-size: 20px; /* Font size for subheaders */
      margin-bottom: 8px; /* Space below the subheader */
  }

  p {
      font-size: 16px; /* Font size for paragraphs */
      line-height: 1.5; /* Line height for readability */
      margin-bottom: 10px; /* Space below paragraphs */
  }

  /* Hide buttons and any interactive elements */
  button, a {
      display: none; /* Hide buttons and links */
  }

  /* Page settings for printing */
  @page {
      size: A4; /* Set page size to A4 */
      margin: 20mm; /* Set margins for the printed page */
  }
}
.usernotverify {
  height: 100vh;
}

.btn-primary:focus {
  box-shadow: none !important;

}
.loading-container.success-container.w-100.position-fixed.top-0.start-0::before {
  content: '';
  position: fixed;
  background: rgb(0, 0, 0, 0.35);
  z-index: 1000;
  height: 100vh;
  width: 100%;
}
.btn {
  border: none;
}

.loading-container
{
  z-index: 100000;
}
/************************************************************************************/

/* Theam setting */

h1 {
  font-size: 42px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 25px !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 17px !important;
  font-weight: 600 !important;
}

h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

h6 {
  font-size: 14px !important;

}

/* font weight */
.fw-6 {
  font-weight: 600 !important;
}

/* font size */
.fs-4 {
  font-size: 28px !important;
}

.fs-5 {
  font-size: 16px !important;
}

.fs-menu {
  font-size: 20px !important;
}

/* text color */
.text-blue {
  color: #002768;
}

/* bg color */
.bg-blue {
  background-color: #002768;
}

.bg-danger {
  background-color: #ff5454;
}

.bg-black {
  background-color: #1e1e1e;
}

.bg-secondary {
  background-color: #ccd4e1 !important;
}

.bg-cyan {
  background-color: #15b097 !important;
}


.rounded-4 {
  border-radius: 10px !important;
}

.rounded-5 {
  border-radius: 25px !important;
}

/************************************************************************************/

/* SideBar */
.padding-20 {
  padding-left: 20% !important;
}

@media screen and (max-width :892px) {
  .padding-20 {
    padding-left: 0% !important;
  }
}

.sidebar {
  background-color: #002768;
  width: 20%;
  position: fixed;
}

.list-group-flush {
  margin-top: 80px;
}

.menu_item {
  background-color: #002768;
  border: none;
  border-radius: 7px !important;

}

.nav .list-group-item .menu_link {
  border-top: 1px solid transparent;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: 1px center;
  padding: 13px 10px 7px 42px;
  display: block;
  margin-bottom: 9px;
}

.menu_link {
  text-decoration: none;
  color: #ffffff;

}

.nav .list-group-item .nav-img1 {
  background-image: url('./assests/user1.svg');
}

.nav .list-group-item .nav-img2 {
  background-image: url('./assests/party1.svg');
}

.nav .list-group-item .nav-img3 {
  background-image: url('./assests/trip1.svg');
}

.nav .list-group-item .nav-img4 {
  background-image: url('./assests/sup1.svg');
}

.nav .list-group-item .nav-img5 {
  background-image: url('./assests/driver1.svg');
}

.nav .list-group-item .nav-img6 {
  background-image: url('./assests/truck1.svg');
}

.menu_item:hover {
  background-color: #ffffff;

  .menu_link {
    color: #002768;
  }
}

.nav .list-group-item .nav-img1:hover {
  background-image: url('./assests/user2.svg');
}

.nav .list-group-item .nav-img2:hover {
  background-image: url('./assests/party2.svg');
}

.nav .list-group-item .nav-img3:hover {
  background-image: url('./assests/trip2.svg');
}

.nav .list-group-item .nav-img4:hover {
  background-image: url('./assests/sup2.svg');
}

.nav .list-group-item .nav-img5:hover {
  background-image: url('./assests/driver2.svg');
}

.nav .list-group-item .nav-img6:hover {
  background-image: url('./assests/truck2.svg');
}


/* User */

.view-btn {
  width: 95px;
}

.overflowScroll {
  overflow-y: scroll;
  scrollbar-width: 0px;
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.shadow.shadow-md.usercard.p-3.rounded.rounded-4.card {
  flex-direction: row !important;

}

.shadow.shadow-md.usercard.p-3.rounded.rounded-4.card img.card-img {
  width: 102px !important;
}

/* Parties */
.form-control:focus {
  box-shadow: none;

}

.form-select:focus {
  box-shadow: none;

}

.parties img.card-img {
  width: 108px !important;
}

.modal-content {
  border-radius: 15px !important;
}

.rounded-top-4 {
  border-radius: 15px 15px 0px 0px;
}

/* Trips */
.dropdown-menu {
  transform: translate(-1px, 49px) !important;
  box-shadow: 1px 2px 5px 1px rgb(212, 211, 211);
}

.btn-primary:hover {
  color: #2d2e30;
  background-color: #002768;
}

/* Trucks */

.btn-truck:hover {
  border: 1px solid #002768;
  border-radius: 14px;
  padding: 1px;
}

.btn-truck_active {
  border: 1px solid #002768;
  border-radius: 14px;
  padding: 1px;
}




/* Media Query */
@media screen and (max-width :1266px) {
  .shadow.shadow-md.usercard.p-3.rounded.rounded-4.flex-wrap.card .card-footer.bg-transparent.border-0.d-flex.flex-column {
    flex-direction: row !important
  }

}

@media screen and (max-width :893px) {
  .sidebar_main {
    display: none;
  }

  .sidebarmob {
    display: block !important;
  }
}



/* **************** Abhishek  addtrip-css *********************************/
.add-trip {
  padding-left: 12px;
}

.add-trip h4 {
  color: #000000;
}

.add-trip h6 {
  color: #4F4F4F;
}

.add-trip .select {
  border-radius: 10px;
}

.cancel {
  border: 1px solid #7991A4;
  color: #4A5E6D;
}

.trip-details {
  padding-bottom: 15px;
  border-bottom: 1px solid #63636326;
}

.amount {
  margin-top: 28px;
}

.horizontal-line {
  border: 1px dashed #656565;
  width: 100%;
}

.card-size {
  width: 49%;
}

/* radius */
.rounded-5 {
  border-radius: 20px;
}

.rounded-6 {
  border-radius: 15px;
}

/*  color  */
.text-gray {
  color: #656565;
}

.text-gray-light {
  color: #15B097;
}

.card-color {
  background-color: #FAFAFA;
}

/* btn-color */
.btn-color {
  background-color: #15B097;
  color: #ffffff;
}

/* font css */
/* .fw-5{font-weight: 500 !important;} */
/* .fs-4{font-size: 10px !important;} */
.fs-3 {
  font-size: 12px !important;
}

.fs-2 {
  font-size: 20px !important;
}

.fs-1 {
  font-size: 28px !important;
}

.fs-7 {
  font-size: 34px !important;
}

.overflowy {
  overflow-y: scroll !important;
}

/* calender css */
/* CollectionReminder.css */

.date-calendar-title {
  font-size: 24px !important;
  font-weight: 400 !important;
}

.date-calendar-container {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}

.date-calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  text-align: start;
}

.date-calendar-footer button {
  color: #002768;
  text-transform: none;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  margin-right: 38px !important;
}

.css-1aqny2q-MuiPickersCalendarHeader-root {
  justify-content: space-around !important;
  padding-left: 18px !important;
  padding-right: 10px !important;
}

.user-dropdown li {
  margin-top: 15px;
}

.dropdown-menu {
  min-width: 260px !important;
  transform: translate3d(-24px, 75px, 0px) !important;
}

.li_menu {
  padding-top: 5px;
}

.pament-magren {
  margin-right: 35px;
}

/* add payment */
.border_btn {
  border: 1px solid black !important;
}

/* settle amount */
.bg-gray {
  background-color: #CCCBCB !important;
}

/* pod_uploaded */
.pod_uploaded {
  width: 100%;
  border: 1px dashed #002768;
  height: 116px;
}

.form-check-input:checked {
  background-color: #002768 !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

::placeholder {
  color: black !important;
}



/* /*******************truck-single************************\ */
.revenue {
  margin-top: 30px !important;
}

.driver_option {
  width: 130px;
}

.driver_details {
  border: 2px solid #656565aa;
}

.months {
  transform: translate3d(0px, 35px, 0px) !important;
}

.month_btn {
  border: 1px solid #000000;
}

.bg-btn-color {
  background-color: #FF5454;
  color: #ffffff;
  border: none;
}

.shadow_trip_card {
  box-shadow: 0px 4px 20px 0px #00000040;
}

.view_color {
  text-decoration: none !important;
  color: #002768 !important;
}

/* .border_btn{border: 2px solid #000000;} */
.months>li {
  padding-left: 10px;
}

.months>li:hover {
  background-color: #002768;
  color: #ffffff;
}

.cash_bg {
  background-color: #4A5E6D;
  color: #ffffff;
  border-radius: 10px;
}

/* fuel book */
.buttons_postion {
  position: absolute;
  bottom: 2px;
  right: 25px;
}

/* /emi book  */
.setting {
  transform: translate3d(70px, 50px, 0px) !important;
  min-width: fit-content !important;
}

.setting>li>NavLink {
  min-width: none;
}

/* trip book */
.shadow_trip_card {
  color: #002768;
}

.shadow_trip_card a {
  text-decoration: none;
  color: #002768;
}



/*************************** Document Book ***************************/
.document_book .document_book_card {
  cursor: pointer;
}

.document_book .document_book_card:hover {
  background-color: #00266826;
  box-shadow: none !important;
  transition-duration: .5s;
}

.document_book .document_book_card:hover .document_book_imgs_div {
  background-color: #ffffff;
}

.document_book .document_book_card .document_book_imgs_div {
  background-color: #00266826;
}

.document_book .document_book_card .document_book_card_desc .document_book_card_desc_expiry {
  background-color: #7B7B7B;
  color: #FAFAFA;
}

/* bilty */
.packing-type {
  padding-top: 50px !important;
}

.form-weight {
  position: relative;
}

.weight-label {
  position: absolute;
  right: 0px;
}

.weight-label .dropdown-menu {
  min-width: 100px !important;
  margin-top: 35px !important;
  transform: none !important;
}


/* media query */
@media screen and (max-width: 1300px) {
  .name h4 {
    font-size: 15px !important;
  }

  .party-name h3 {
    font-size: 20px !important;
  }

  .party-info>h5 {
    font-size: 14px !important;
  }

  .party-info>h4 {
    margin-bottom: 12px !important;
  }

  .profit>h4 {
    font-size: 15px !important;
  }

  .progress_bar img {
    width: 95%;
  }
}

@media screen and (max-width:1200px) {
  .driver-info {
    margin-left: 5px !important;
  }

  .driver-info h4 {
    font-size: 14px !important;
  }

  .name h4 {
    font-size: 12px !important;
  }

  .party-name img {
    height: 80px;
  }

  .party-name h3 {
    font-size: 16px !important;
  }

  .party-name h3 {
    font-size: 16px !important;
  }

  .party-name h4 {
    font-size: 14px !important;
  }

  .party-info>h5 {
    font-size: 12px !important;
  }

  .party-info>h4 {
    font-size: 12px !important;
  }

  .party-info>h4 {
    margin-bottom: 11px !important;
  }

  .progress_bar_items {
    gap: 22px !important;
  }

  .driver-icon {
    height: 38px !important;
  }

  .pament-magren {
    font-size: 12px !important;
    margin-right: 15px !important;
  }

  .driver_option {
    width: 48% !important;
  }

  .truck-details h4 {
    font-size: 14px !important;
  }

  .revenue h5 {
    font-size: 14px !important;
  }

  .driver-option-img {
    width: 30%;
  }

  .driver-option-img-text {
    font-size: 100% !important;
  }

  /* .driver_cards{flex-direction: column;} */
}

@media screen and (max-width:1000px) {
  .md-w-100 {
    width: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  .media-fs {
    font-size: 20px !important;
    padding-left: 10px;
  }
}

@media screen and (max-width: 875px) {
  .truck-driver-info {
    padding-left: 0px !important;
    padding-right: 15px !important;
    max-width: none;
  }

  .truck-driver-header a {
    font-size: 70% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .truck-driver-header img {
    width: 30% !important;
  }

  .margin-btn-add-trip {
    font-size: 70% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-right: 0px !important;
    background-color: #002768;
  }

  .media-fs {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 576px) {
  .media-fs {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 462px) {
  .reg-no_headed {
    display: none !important;
  }

  .truck-no {
    display: flex !important;
  }
}


/* Bilty Page */
.trip_complete_btn {
  border: 1px solid #15B097;
  padding: 10px 88px;
  background-color: #ffffff;
}

.trip_complete_btn:hover {
  background-color: #15B097;
  color: #ffffff;
}

.pointer {
  cursor: pointer;
}

.btn-cyan {
  background-color: #15B097 !important;
  border: 1px solid #15B097;
  padding: 10px 88px;
  background-color: #ffffff;
  color: #ffffff;
}

select {
  -webkit-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.text-justify {
  text-align: justify;
}


.table-border {
  border: 1px solid #000000;
}


.login_bg_image {
  background-image: url('./assests/login_img1.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  animation-name: login_bg;
  animation-duration: 11s;
  animation-iteration-count: infinite;
}

@keyframes login_bg {
  /* 0% {background-image: url('./assests/login_img1.svg');}
  33% {background-image: url('./assests/login_img2.svg')}
  66% {background-image: url('./assests/login_img3.svg')}
  99% {background-image: url('./assests/login_img1.svg');} */
}

.full_height {
  height: 100vh;
}

.rotate {
  transform: rotateY(180deg);
  transition: transform 0.6s;
}

.rotate_rev {
  transform: rotateY(360deg);
  transition: transform 0.6s;
}

.parties_dropdown_menu {
  transform: translate(-1px, 49px) !important;
  min-width: min-content !important;
}

.parties_dropdown_menu_btn:after {
  display: none !important;
}

.menu_link_active {
  color: #002768 !important;
}

.menu_item_active {
  background-color: #ffffff;
}

.nav_img3_active {
  background-image: url('./assests/trip2.svg') !important;
}

.nav_img1_active {
  background-image: url('./assests/user2.svg') !important;
}

.nav_img2_active {
  background-image: url('./assests/party2.svg') !important;
}

.nav_img4_active {
  background-image: url('./assests/sup2.svg') !important;
}

.nav_img5_active {
  background-image: url('./assests/driver2.svg') !important;
}

.nav_img6_active {
  background-image: url('./assests/truck2.svg') !important;
}

.user_menu_dropdown {
  left: auto !important;
  top: 0px !important;
  right: 0px !important;
}

.bg-consignor {
  background-color: #d4e38b;
}

.bg-consignee {
  background-color: #c1adce;
}

.input-group-text {
  background-color: transparent;
  border: none;
}